import React from "react"
import useLanguage from "../hooks/use-language"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { css } from "@emotion/core"

const LanguageSwitcher = ({ ...props }) => {
  const otherLng = useLanguage() === "en" ? "de" : "en"
  const flags = useStaticQuery(graphql`
    {
      uk: file(relativePath: { eq: "uk.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      de: file(relativePath: { eq: "de.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <a
      href={
        otherLng === "en"
          ? "https://christinaroettgers.com/"
          : "https://christinaroettgers.de/"
      }
      {...props}
      css={css`
        display: flex !important;
        align-content: center;
      `}
    >
      View in{" "}
      {otherLng === "en" ? (
        <Image
          fluid={flags.uk.childImageSharp.fluid}
          css={css`
            margin: 0 0.25rem;
            display: inline-block;
            width: 1.125rem;
          `}
        />
      ) : (
        <Image
          fluid={flags.de.childImageSharp.fluid}
          css={css`
            margin: 0 0.25rem;
            display: inline-block;
            width: 1.125rem;
          `}
        />
      )}
    </a>
  )
}

export default LanguageSwitcher
