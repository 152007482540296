import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import LanguageSwitcher from "./language-switch"
import { css } from "@emotion/core"

const Navigation = ({ hideLogo }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 48, width: 195) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <nav
      className={`navbar navbar-light navbar-expand-md`}
      css={css`
        background-color: #fefff7;
      `}
    >
      <div className="container">
        {!hideLogo && (
          <Link className="navbar-brand" to="/">
            <Image fixed={data.file.childImageSharp.fixed} />
          </Link>
        )}
        <button
          data-toggle="collapse"
          data-target="#navcol-1"
          className="navbar-toggler"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navcol-1">
          <ul className="nav navbar-nav ml-auto">
            <li role="presentation" className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li role="presentation" className="nav-item">
              <Link className="nav-link" to="/blog/">
                Blog
              </Link>
            </li>
            <li role="presentation" className="nav-item">
              <Link className="nav-link" to="/kontakt/">
                Contact
              </Link>
            </li>
            <li role="presentation" className="nav-item">
              <LanguageSwitcher className="nav-link" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
