import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { css } from "@emotion/core"
import LanguageSwitcher from "./language-switch"
import Navigation from "./navigation"

const BannerNavigation = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 96, width: 390) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Navigation hideLogo />
      <nav
        className="container-fluid text-center"
        css={css`
          display: flex;
          flex-flow: column wrap;
          align-content: center;
          justify-content: center;
          height: 50vh;

          ul {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
          }

          li {
            padding: 0 2rem;
          }

          .navbar-brand img {
          }
        `}
      >
        <Link className="navbar-brand" to="/">
          <Image fixed={data.file.childImageSharp.fixed} />
        </Link>
      </nav>
    </>
  )
}

export default BannerNavigation
