import React from "react"
import Helmet from "react-helmet"
import Navigation from "./navigation"
import { css } from "@emotion/core"
import Footer from "../components/footer"
import BannerNavigation from "./banner-navigation"

const Layout = ({ lead, children }) => (
  <div
    css={css`
      color: #084857;
      font-size: 1.125rem;
      letter-spacing: 0.75px;
      background-color: #fefff7;

      .text-muted,
      .figure-caption,
      .nav-link,
      a {
        color: #084857 !important;
      }

      li {
        line-height: 1;
        padding: 0.25rem 0;
      }

      section {
        margin-top: 2.5rem;
      }

      *:focus {
        outline: none;
      }
    `}
  >
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
        integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2"
        crossOrigin="anonymous"
      />

      <link rel="stylesheet" href="MyFontsWebfontsKit.css" type="text/css" />

      <script
        src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
        integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
        crossOrigin="anonymous"
        async
        defer
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ho+j7jyWK8fNQe+A12Hb8AhRq26LrZ/JpcUGGOn+Y7RsweNrtN/tE3MoK7ZeZDyx"
        crossOrigin="anonymous"
        async
        defer
      />
    </Helmet>
    {lead ? <BannerNavigation /> : <Navigation />}
    <main>{children}</main>

    <Footer />
  </div>
)

export default Layout
