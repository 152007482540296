import { useStaticQuery, graphql } from "gatsby";

export default function useLanguage() {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    language
                }
            }
        }
    `)

    return data.site.siteMetadata.language
}