import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const Footer = () => (
  <footer
    className="container mt-4 mb-4"
    css={css`
      background-color: #fefff7;
    `}
  >
    <nav className="nav justify-content-between">
      <ul className="nav">
        <li className="nav-item text-muted text-xs mx-1">
          <a
            href="mailto:contact@christinaroettgers.com"
            className="text-muted"
          >
            Christina Röttgers
          </a>
        </li>
        <li className="nav-item text-muted text-xs mx-1">
          <a href="mailto:marie@moculade.de" className="text-muted">
            Designed by Marie-Susann Kühr, Moculade
          </a>
        </li>
        <li className="nav-item text-muted text-xs mx-1">
          <a href="https://roettgers.co" className="text-muted">
            Programmed by Bruce Röttgers
          </a>
        </li>
      </ul>
      <ul className="nav">
        <li className="nav-item text-muted text-xs mr-2">
          <Link to="privacy/" className="text-muted">
            Privacy Policy
          </Link>
        </li>
        <li className="nav-item text-muted text-xs">
          <Link to="imprint/" className="text-muted">
            Impressum
          </Link>
        </li>
      </ul>
    </nav>
  </footer>
)

export default Footer
